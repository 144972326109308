import Container from '@app/components/Container';
import TypedLink from '@app/components/TypedLink';
import withPublic from '@app/hoc/withPublic';

function HomePage() {
  return (
    <Container>
      <h1>
        <TypedLink to="/login">
          <a>To Login Page</a>
        </TypedLink>
      </h1>
      <h1>
        <TypedLink to="/sign-up/client">
          <a>To Registration Page Client</a>
        </TypedLink>
      </h1>
      <h1>
        <TypedLink to="/sign-up/therapist">
          <a>To Registration Page Therapist</a>
        </TypedLink>
      </h1>
    </Container>
  );
}

export default withPublic(HomePage);
