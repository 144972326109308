import { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  children?: ReactNode;
}

const Container: FC<Props> = ({ className, children }) => {
  return <Root className={className}>{children}</Root>;
};

const Root = styled.div`
  position: relative;
  padding-left: 9.72%;
  padding-right: 9.72%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 1440px) {
    padding-left: 140px;
    padding-right: 140px;
  }
  @media (max-width: 1440px) {
    padding-left: 100px;
    padding-right: 100px;
  }
  @media (max-width: 1300px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media (max-width: 1024px) {
    padding-left: 32px;
    padding-right: 32px;
  }
  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export default Container;
