import { FC } from 'react';
import styled from 'styled-components';
import Spinner from '../Spinner';

interface Props {
  className?: string;
}

const LoadingState: FC<Props> = ({ className }) => {
  return (
    <Root className={className}>
      <Spinner isLoading width={32} height={32} />
    </Root>
  );
};

export default LoadingState;

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
