import { buildUrl, PathParams, RoutesPath } from '@app/routers';
import Link, { LinkProps } from 'next/link';
import React, { FC, ReactNode } from 'react';
import { UrlObject } from 'url';

type TypedToParams<P> = Omit<UrlObject, 'pathname'> & { pathname: P };

export type TypedLinkProps<P extends RoutesPath> = {
  to: P | TypedToParams<P>;
  component?: React.ComponentType<unknown>;
  redirect?: string;
  params?: PathParams<P>;
  replace?: boolean;
  children?: ReactNode;
} & Omit<LinkProps, 'href'>;

const TypedLink: FC<TypedLinkProps<RoutesPath>> = ({
  to,
  params = {},
  replace,
  children,
}) => {
  return typeof to === 'object' ? (
    <Link href={to} replace={replace} passHref>
      {children}
    </Link>
  ) : (
    <Link href={buildUrl(to, params)} replace={replace} passHref>
      {children}
    </Link>
  );
};

export default TypedLink;
