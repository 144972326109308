import { COLORS } from '@app/constants';
import { Spinner as Icon } from '@app/ui/iconComponents';
import { FC } from 'react';
import styled, { css, keyframes } from 'styled-components';

interface Props {
  isLoading?: boolean;
  color?: string;
  className?: string;
  width?: number;
  height?: number;
}

const Spinner: FC<Props> = ({
  isLoading = false,
  className,
  color = COLORS.primary300,
  width = 20,
  height = 20,
}) => {
  return (
    <Root className={className} $isLoading={isLoading} $color={color}>
      <Icon width={width} height={height} />
    </Root>
  );
};

export default Spinner;

interface StyleProps {
  $isLoading?: boolean;
  $color?: string;
}

const rotate = keyframes`
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
`;

const Root = styled.div<StyleProps>`
  svg {
    fill: none;

    path {
      fill: ${({ $color }) => $color};
    }

    circle {
      stroke: ${({ $color }) => $color};
    }
  }

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  ${({ $isLoading }) =>
    $isLoading
      ? css`
          & > svg {
            animation: ${rotate} 0.8s linear infinite;
          }
        `
      : css`
          opacity: 0;
        `};
`;
